import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Row,
  Tab,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { tambahSupervisor, getAllSupervisor, getAllClient } from "stores";
import { getAllCabang } from "stores";
import { penempatanSupervisor } from "stores";
import { editStatusSupervisor } from "stores";
import { resetPassword } from "stores";
import { Dna } from "react-loader-spinner";
import { settingMenuSS } from "stores";

function ListSupervisor() {
  const dispatch = useDispatch();
  const storeClient = useSelector((state) => state.clientReducer);
  const supervisor = useSelector((state) => state.supervisorReducer);
  const storeCabang = useSelector((state) => state.cabangReducer);
  const auth = useSelector((state) => state.authReducer);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const [modalSpv, setModalSpv] = React.useState(false);
  const [modalPenempatan, setModalPenempatan] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalUbahPenempatan, setModalUbahPenempatan] = React.useState(false);
  const [pilihClient, setPilihClient] = React.useState(null);
  const [nama, setNama] = React.useState("");
  const [nik, setNik] = React.useState("");
  const [nopeg, setNopeg] = React.useState("");
  const [listSpv, setListSpv] = React.useState([]);
  const [listClient, setListClient] = React.useState([]);
  const [idClientSpv, setIdClientSpv] = React.useState("");
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState(null);
  const [pilihCabang, setPilihCabang] = React.useState(null);
  const [idSupervisor, setIdSupervisor] = React.useState("");
  const [listDetailCabang, setListDetailCabang] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const [modalSettingMenu, setModalSettingMenu] = React.useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleNikChange = (e) => {
    const inputNik = e.target.value;
    const formattedNik = inputNik.slice(0, 16);
    setNik(formattedNik);
  };

  const remainingDigits = 16 - nik.length;

  const submitSettingMenu = () => {
    const dataToSend = {
      idSupervisor: idSupervisor,
      menu: multipleSelect.map((option) => option.value),
    };
    settingMenuSS(dataToSend)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          getAllSupervisor(dispatch, { idClient: "" });
          setModalSettingMenu(false);
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  };

  const submitSupervisor = () => {
    if (nik.length < 16) {
      Swal.fire({
        title: "NIK harus 16 digit !!",
        icon: "warning",
      });
      return;
    }
    tambahSupervisor({
      nik: nik,
      nama: nama,
      nopeg: nopeg,
      idClient: idClientSpv,
    })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          getAllSupervisor(dispatch, { idClient: "" });
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const checkStatus = () => {
    if (status.value === undefined || status === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Status wajib diisi !",
      });
    } else {
      submitEditStatus();
    }
  };

  const submitEditStatus = () => {
    editStatusSupervisor({
      idSupervisor: idSupervisor,
      status: status.value,
    })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          getAllSupervisor(dispatch, { idClient: "" });
          setModalEdit(false);
          setStatus("");
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  };

  const submitPenempatan = () => {
    if (pilihCabang === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Cabang tidak boleh kosong",
      });
    } else {
      penempatanSupervisor({
        idSupervisor: idSupervisor,
        idCabang: idCabang,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          // setIdCabang("");
          // setPilihCabang("");
          getAllSupervisor(dispatch, { idClient: "" });
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  const handleModalClose = () => {
    setPilihCabang(null);
    setIdCabang(null);
    setPilihClient(null);
    setModalPenempatan(false);
    setModalSpv(false);
    setIdClientSpv("");
    setPilihClient("");
    setNik("");
    setNopeg("");
    setNama("");
  };

  React.useEffect(() => {
    let tmp =
      supervisor.listSupervisor &&
      supervisor.listSupervisor.map((val) => {
        const ubahPenempatan = val.penempatan != "";
        return {
          ...val,
          action: (
            <>
              {/* {ubahPenempatan ? (
                <Button
                  className="mr-0"
                  variant="warning"
                  style={{ marginTop: 5 }}
                  onClick={() => {
                    setIdSupervisor(val._id);
                    getAllCabang(dispatch, { idClient: val.idClient });
                    setModalUbahPenempatan(!modalUbahPenempatan);
                  }}
                >
                  Ubah Penempatan
                </Button>
              ) : ( */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Penempatan supervisor</Tooltip>}
              >
                <Button
                  disabled={val.status === "aktif" ? false : true}
                  className="mr-1"
                  variant="danger"
                  style={{
                    marginTop: 5,
                    cursor:
                      val.status === "tidak aktif" ? "not-allowed" : "pointer",
                    backgroundColor:
                      val.status === "tidak aktif" ? "grey" : "#447DF7",
                    border: 0,
                    padding: "7px 16px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setIdSupervisor(val._id);
                    getAllCabang(dispatch, { idClient: val.idClient });
                    setModalPenempatan(!modalPenempatan);
                  }}
                >
                  <i class="fas fa-map-marked-alt"></i>
                </Button>
              </OverlayTrigger>{" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Edit Status</Tooltip>}
              >
                <Button
                  className="mr-1"
                  variant="success"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setIdSupervisor(val._id);
                    setModalEdit(!modalEdit);
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>{" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Reset Password</Tooltip>}
              >
                <Button
                  className="mr-0"
                  variant="danger"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={async () => {
                    const result = await Swal.fire({
                      title: "Reset Password",
                      text: `Apakah Anda yakin ingin mereset password pengguna ${val.nama}?`,
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ya, Reset Password!",
                    });

                    if (result.isConfirmed) {
                      try {
                        const response = await resetPassword({
                          idUser: val._id,
                          role: val.role,
                        });

                        Swal.fire({
                          title: "Sukses",
                          text: response.data.message,
                          icon: "success",
                        });
                      } catch (error) {
                        Swal.fire({
                          title: "Error",
                          text: error.response.data.message,
                          icon: "error",
                        });
                      }
                    }
                  }}
                >
                  <i class="fas fa-redo-alt"></i>
                </Button>
              </OverlayTrigger>
              {auth.role === "super admin" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Setting Menu</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    style={{
                      marginTop: 5,
                      backgroundColor: "#496679",
                      border: 0,
                      padding: "7px 16px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setModalSettingMenu(!modalSettingMenu);
                      setIdSupervisor(val._id);
                    }}
                  >
                    <i class="fas fa-user-cog"></i>
                  </Button>
                </OverlayTrigger>
              ) : null}
            </>
          ),
        };
      });
    setListSpv(tmp);
  }, [supervisor.listSupervisor, auth]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeCabang.listCabang &&
      storeCabang.listCabang.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaCabang,
        });
      });

    setListCabang(tmp);
  }, [storeCabang.listCabang]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });

    setListClient(tmp);
  }, [storeClient.listClient]);

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colSupervisor = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Aksi",
      minWidth: "350px",
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      center: true,
      selector: (row) => row.action,
    },
    {
      name: "Kode User",
      minWidth: "150px",
      center: true,
      selector: (row) => row.kodeUser,
    },
    {
      name: "No Karyawan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Jabatan",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.role === "supervisor" ? "Supervisor" : "Supervisor",
    },
    {
      name: "BPD",
      minWidth: "200px",
      center: true,
      omit: auth.role === "super admin" ? false : true,
      selector: (row) => row.namaClient[0],
      // row.penempatan.length === 0 ? (
      //   <div style={{ fontWeight: "bold" }}>Belum Penempatan</div>
      // ) : (
      //   row.penempatan[0].namaClient
      // ),
    },
    {
      name: "Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        row.penempatan.length === 1 && !row.penempatan[0].namaCabang ? (
          <div style={{ fontWeight: "bold" }}>Belum Penempatan</div>
        ) : row.penempatan.length === 1 ? (
          row.penempatan[0].namaCabang
        ) : (
          <>
            <div>{row.penempatan[0].namaCabang},...</div>
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                setListDetailCabang(row);
                setModalDetail(true);
              }}
            >
              Lihat Semua
            </div>
          </>
        ),
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    getAllSupervisor(dispatch, { idClient: "" })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    getAllClient(dispatch);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalSettingMenu}
        onHide={() => setModalSettingMenu(!modalSettingMenu)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Setting Menu
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <Col md="8"> */}
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                placeholder="Pilih Menu"
                name="multipleSelect"
                closeMenuOnSelect={false}
                isMulti
                value={multipleSelect}
                onChange={(value) => setMultipleSelect(value)}
                options={[
                  {
                    value: "",
                    label: " Pilih Menu",
                    isDisabled: true,
                  },
                  { value: "Dashboard", label: "Dashboard" },
                  { value: "Client", label: "Client" },
                  { value: "User", label: "User" },
                  { value: "Cabang", label: "Cabang" },
                  { value: "Target", label: "Target" },
                  { value: "Pengajuan", label: "Pengajuan" },
                  { value: "Pencairan", label: "Pencairan" },
                  { value: "Kunjungan", label: "Kunjungan" },
                  { value: "ListKunjungan", label: "List Kunjungan" },
                ]}
              />
              {/* </Col> */}
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitSettingMenu}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalUbahPenempatan}
        onHide={() => {
          handleModalClose();
          setModalUbahPenempatan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ color: "black" }}>
                Form Ubah Penempatan Supervisor
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Cabang</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Cabang"
                    value={pilihCabang}
                    onChange={(value) => {
                      setPilihCabang(value);
                      setIdCabang(value.value);
                    }}
                    options={listCabang}
                    placeholder="Pilih Cabang"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                // onClick={submitUbahPenempatan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ color: "black" }}>
                Detail Penempatan
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Nama
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {listDetailCabang.nama}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Jabatan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {listDetailCabang.role === "supervisor"
                        ? "Supervisor"
                        : "Supervisor"}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Penempatan
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <ul>
                      {listDetailCabang.penempatan &&
                        listDetailCabang.penempatan.map((val) => {
                          return (
                            <li>
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              >
                                {val.namaCabang}
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalPenempatan}
        onHide={() => {
          handleModalClose();
          setModalPenempatan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ color: "black" }}>
                Form Penempatan Supervisor
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Cabang</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Cabang"
                    value={pilihCabang}
                    onChange={(value) => {
                      setPilihCabang(value);
                      setIdCabang(value.value);
                    }}
                    options={listCabang}
                    placeholder="Pilih Cabang"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitPenempatan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalEdit}
        onHide={() => {
          setStatus("");
          setModalEdit(!modalEdit);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4">Edit Status</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Status</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={status}
                    onChange={(selectedOption) => setStatus(selectedOption)}
                    // onChange={setStatus}
                    options={[
                      { value: "true", label: "Aktif" },
                      { value: "false", label: "Tidak Aktif" },
                    ]}
                    placeholder="Edit Status"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={checkStatus}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Tab.Content>
        <Card style={{ border: 0 }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              md={"4"}
              style={{
                marginTop: 20,
                marginRight: 25,
              }}
            >
              {auth.role === "super admin" || auth.role === "admin" ? (
                <Button
                  className="btn-wd mr-1"
                  // variant="primary"
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    backgroundColor: "#6CC417",
                    border: 0,
                    fontWeight: "bold",
                  }}
                  onClick={() => setModalSpv(!modalSpv)}
                >
                  Tambah Supervisor
                </Button>
              ) : null}
            </Col>

            <Col md={"4"} style={{ marginTop: 25, marginRight: 25, order: 1 }}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="nc-icon nc-zoom-split icon-bold"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  style={{
                    paddingLeft: "10px",
                  }}
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="No Karyawan/Nama Supervisor"
                />
              </InputGroup>
            </Col>
          </Row>
        </Card>
      </Tab.Content>

      <Row>
        <Col md="12">
          {isLoading ? (
            <div
              className="loader-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
              <p>Loading...</p>
            </div>
          ) : (
            <DataTable
              columns={colSupervisor}
              data={listSpv.filter(
                (row) =>
                  row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                  row.nopeg.toLowerCase().includes(searchText.toLowerCase())
              )}
              // data={listSpv}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
              paginationPerPage={itemsPerPage}
              onChangePage={handlePageChange}
            />
          )}
        </Col>
      </Row>

      <Modal
        size="lg"
        show={modalSpv}
        onHide={() => {
          handleModalClose();
          setModalSpv(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Supervisor
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    NIK
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Control
                      onChange={handleNikChange}
                      value={nik}
                      type="text"
                      style={{ flex: 1 }}
                      placeholder="NIK"
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {remainingDigits} digit
                    </div>
                  </div>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNama(e.target.value);
                    }}
                    placeholder="Nama"
                    type="text"
                  ></Form.Control>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    No Karyawan
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNopeg(e.target.value);
                    }}
                    placeholder="No Karyawan"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                {auth.role === "super admin" ? (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Pilih Client
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="Pilih Client"
                      value={pilihClient}
                      onChange={(value) => {
                        setPilihClient(value);
                        setIdClientSpv(value.value);
                      }}
                      options={listClient}
                      placeholder="Pilih Client"
                    />
                  </Form.Group>
                ) : null}
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={submitSupervisor}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default ListSupervisor;
