import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

function getRouting(routes, val) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].name === val) {
      return routes[i].layout + routes[i].path;
    }
    if (routes[i].collapse) {
      return getRouting(routes[i].views, val);
    }
  }
  return "/";
}

export function login(dispatch, data, history) {
  Swal.fire({
    title: "Tunggu ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/webLogin/loginUser", data)
    .then(async (respon) => {
      let { nama, nopeg, role, idClient, idCabang, idUser, kodeUser, menu } =
        respon.data;

      if (role === "marketing" || role === "direct marketing") {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Maaf Anda Tidak Memiliki Akses Website!",
        });
      } else {
        Swal.close();
        localStorage.setItem("token", respon.data.token);
        dispatch({
          type: "SET_IDENTITY",
          data: {
            nama,
            nopeg,
            role,
            idClient,
            idCabang,
            idUser,
            kodeUser,
            menu,
          },
        });
        history.push("/admin/dashboard");
      }
    })
    .catch(errorHandler);
}

export function gantiPassword(dispatch, data, history) {
  baseAxios
    .post("webLogin/gantiPassword", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    })
    .then(async (respon) => {
      Swal.fire({
        position: "center",
        icon: "success",
        title: respon.data.message,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("token");
          window.location.replace("/auth/login-page");
        }
      });
    })
    .catch(errorHandler);
}

export async function resetPassword(data) {
  // console.log(data, "PPPPPPP");
  try {
    const response = await baseAxios.post("webLogin/resetPassword", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

// export function resetPassword(dispatch, data, history) {
//   console.log(data, "LLLLLLLL");
//   baseAxios
//     .post("webLogin/resetPassword", data, {
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     })
//     .then(async (respon) => {
//       console.log(respon);
//       Swal.fire({
//         position: "center",
//         icon: "success",
//         title: respon.data.message,
//         showConfirmButton: true,
//       }).then((result) => {
//         if (result.isConfirmed) {
//           localStorage.removeItem("token");
//           window.location.replace("/auth/login-page");
//         }
//       });
//     })
//     .catch(errorHandler);
// }

export function refresh(dispatch) {
  Swal.fire({
    title: "Tunggu ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get("/webLogin/refreshWeb", {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      let { nama, nopeg, role, idClient, idCabang, idUser, kodeUser, menu } =
        respon.data;
      localStorage.setItem("token", respon.data.token);
      dispatch({
        type: "SET_IDENTITY",
        data: { nama, nopeg, role, idClient, idCabang, idUser, kodeUser, menu },
      });
      Swal.close();
    })
    .catch(errorHandler);
}

export function logout() {
  localStorage.removeItem("token");
  window.location.replace("/auth/login-page");
}
