import { baseAxios } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export async function getAllTotalSupervisor(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalSupervisor", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_SUPERVISOR", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalAreaManager(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalAreaManager", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_AREAMANAGER", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalMarketing(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalMarketing", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_MARKETING", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalCabang(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalCabang", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_CABANG", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalPengajuan(dispatch) {
  try {
    const response = await baseAxios.get("webPengajuan/getAllTotalPengajuan", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_AMBIL_PENGAJUAN", data: response.data.data });
    dispatch({ type: "SET_ALL_TOTAL_PENGAJUAN", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanCabang(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getGrafikPencairanCabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanUser(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getGrafikPencairanPerBulanByLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    // console.log(response, "PPPPPPPP");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getExportPengajuan(data) {
  Swal.fire({
    title: "Konfirmasi Pengajuan",
    text: `Apakah Anda ingin mengexport dari ${moment(data.tglAwal).format(
      "DD MMMM YYYY"
    )} sampai ${moment(data.tglAkhir).format("DD MMMM YYYY")} ?`,
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Ya, Export",
    cancelButtonText: "Batal",
    allowOutsideClick: () => !Swal.isLoading(),
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return new Promise((resolve) => {
        baseAxios
          .get(
            `webPengajuan/exportPengajuan?tglAwal=${data.tglAwal}&tglAkhir=${
              data.tglAkhir
            }&statusPengajuan=${
              data.statusPengajuan === undefined || data.statusPengajuan === ""
                ? ""
                : data.statusPengajuan
            }`,
            {
              headers: { token: localStorage.getItem("token") },
              responseType: "blob",
            }
          )
          .then((respon) => {
            console.log(respon, "KKKK");
            const url = window.URL.createObjectURL(new Blob([respon.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `exportPengajuan.xlsx`);
            document.body.appendChild(link);
            link.click();
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Export Berhasil",
              text: "Data berhasil diexport.",
            });
            resolve();
          })
          .catch((err) => {
            console.log(err, "ERR");
            Swal.showValidationMessage(`Gagal melakukan ekspor: ${err}`);
            resolve();
          });
      });
    },
  });
}
