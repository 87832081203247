import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { getAllTotalAreaManager } from "stores";
import { getAllTotalPengajuan } from "stores";
import ReactDatetime from "react-datetime";
import { getTotalPencairan } from "stores/Pengajuan/function";
import { getGrafikPencairanCabang } from "stores";
import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { getTotalTargetUser } from "stores/Pengajuan/function";
import { getGrafikPencairanUser } from "stores";
import { getAllTotalSupervisor } from "stores";
import { getAllTotalMarketing } from "stores";
import { getAllTotalCabang } from "stores";
import { getExportPengajuan } from "stores";
import { refresh } from "stores";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DashboardUtama() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const cardTotalAreaManager = useSelector(
    (state) => state.dashboardUtamaReducer
  );
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducer);
  // console.log(cardTotalPencairan.data, "Dari Store");
  const cardTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer
  );
  const tampilTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer.totalAllPengajuan
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const totalTarget = hasilPresentase > 100;
  const [totalNominalPencairan, setTotalNominalPencairan] = React.useState(0);
  const [hasilPresentase, setHasilPresentase] = React.useState(0);
  const [totalNominalTarget, setTotalNominalTarget] = React.useState(0);
  const [dataGrafikCabang, setDataGrafikCabang] = React.useState([]);
  const [dataGrafikUser, setDataGrafikUser] = React.useState([]);
  const [totalSpv, setTotalSpv] = React.useState([]);
  const [totalCbg, setTotalCbg] = React.useState([]);
  const [totalAM, setTotalAM] = React.useState([]);
  const [totalMarketing, setTotalMarketing] = React.useState([]);
  const [dataAllGrafikCabang, setDataAllGrafikCabang] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [tglAwal, setTglAwal] = React.useState("");
  const [tglAkhir, setTglAkhir] = React.useState("");
  const [modalExport, setModalExport] = React.useState(false);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [modalDetail, setModalDetail] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const pencairanData = dataGrafikCabang.map((item) => item.totalPencairan);
  const targetData = dataGrafikCabang.map((item) => item.totalTarget);
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);
  const bulanData = dataGrafikUser.map((item) => item.bulan);
  const pencairanDataUser = dataGrafikUser.map(
    (item) => item.totalNominalPencairan
  );
  const targetDataUser = dataGrafikUser.map((item) => item.totalNominalTarget);
  const abjadCabang = dataGrafikCabang.map((_, index) =>
    String.fromCharCode(65 + index)
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closeModal = () => {
    setCurrentPage(1);
    setModalDetail(false);
  };

  const chartData = {
    labels: abjadCabang,
    series: [pencairanData, targetData],
  };

  const chartDataUser = {
    labels: bulanData,
    series: [pencairanDataUser, targetDataUser],
  };

  const handleExportPengajuan = () => {
    setStatus("");
    setTglAwal("");
    setTglAkhir("");
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  React.useEffect(() => {
    getAllTotalPengajuan(dispatch);
  }, []);

  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    getTotalPencairan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairan(totalPencairan);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalTargetUser(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTarget(totalTarget);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanCabang(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 15) {
          tmp.push(val);
        }
      });
      setDataGrafikCabang(tmp);
      setDataAllGrafikCabang(grafikCabang);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanUser(dispatch, {
      tahun: tahun,
    }).then((response) => {
      const grafikUser = response.data;
      setDataGrafikUser(grafikUser);
    });
  }, [tahun]);

  React.useEffect(() => {
    if (totalNominalPencairan !== 0 && totalNominalTarget !== 0) {
      const presentase = Math.round(
        (totalNominalPencairan / totalNominalTarget) * 100
      );
      setHasilPresentase(presentase);
    } else {
      setHasilPresentase(0);
    }
  }, [totalNominalPencairan, totalNominalTarget]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabangPencairan = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalPencairan.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalTarget.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Nasabah",
      minWidth: "120px",
      center: true,
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      selector: (row) => row.totalNasabah,
    },
  ];

  React.useEffect(() => {
    getAllTotalSupervisor(dispatch).then((response) => {
      const dataSupervisor = response.data;
      setTotalSpv(dataSupervisor);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalAreaManager(dispatch).then((response) => {
      const dataAreaManager = response.data;
      setTotalAM(dataAreaManager);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalMarketing(dispatch).then((response) => {
      const dataMarketing = response.data;
      setTotalMarketing(dataMarketing);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalCabang(dispatch).then((response) => {
      const dataCabang = response.data;
      setTotalCbg(dataCabang);
    });
  }, []);

  const submitExport = (e) => {
    e.preventDefault();
    // console.log(tglAwal, tglAkhir, status.value, "ASLKASDASDSAD");
    // if (status === "") {
    //   Swal.fire({
    //     title: "Status Harus Diisi",
    //     icon: "warning",
    //   });
    //   return;
    // }
    getExportPengajuan({
      tglAwal: tglAwal,
      tglAkhir: tglAkhir,
      statusPengajuan: status.value,
    });
  };

  React.useEffect(() => {
    if (auth.role === "client") {
      const interval = setInterval(() => {
        window.location.reload();
        refresh();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [auth.role]);

  return (
    <>
      <Modal
        size="lg"
        show={modalDetail}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairan}
                data={dataAllGrafikCabang}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStyles}
                pagination
                paginationPerPage={itemsPerPage}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
          handleExportPengajuan();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{ fontWeight: "bold", textTransform: "none" }}
                      >
                        Export Pengajuan
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Awal
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Akhir
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTglAkhir(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Status
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatus(value)}
                            options={[
                              {
                                value: "",
                                label: "Pilih Status",
                                isDisabled: true,
                              },
                              {
                                value: "Disetujui",
                                label: "Disetujui",
                              },
                              // {
                              //   value: "Menunggu Konfirmasi",
                              //   label: "Menunggu Konfirmasi",
                              // },
                            ]}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitExport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="6">
            <Card
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: 10,
                marginTop: 15,
              }}
            >
              <Card.Header
                className="d-none d-md-inline"
                style={{
                  backgroundColor: "#10A674",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Card.Title as="h3">
                  <Row>
                    <Col
                      md="8"
                      className="p-3"
                      style={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      <b style={{ color: "white" }}>bpd kalteng</b>
                    </Col>
                    <Col md="4" className="p-2 d-flex justify-content-end">
                      <Image
                        src={
                          "https://ptbap.net/assets/images/logo/invert-bap.png"
                        }
                        width="auto"
                        height="50px"
                      />
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              {/* <Card.Body
                style={{
                  backgroundColor: "white",
                }}
              > */}
              <Row>
                <Col
                  lg="5"
                  className="d-none d-lg-flex align-items-center justify-content-center"
                >
                  <Image
                    src={
                      "https://play-lh.googleusercontent.com/HPc-FLGZnSwC2E4Tvkt97h7vUvyVtoJixG4tEn3ZQhjpqhr3s8_NGugb0qpijxEURw=w240-h480-rw"
                    }
                    width="auto"
                    height="150px"
                  />
                </Col>
                <Col lg="7" xs="12">
                  <div className="numbers d-flex justify-content-around">
                    <div className="d-flex flex-column align-items-center">
                      <p className="card-category">
                        <h3>
                          <b
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 27,
                            }}
                          >
                            Pencapaian
                          </b>
                        </h3>
                      </p>
                      <Card.Title
                        as="h2"
                        style={{
                          color: hasilPresentase > 100 ? "#26DB53" : "#FF4D4F",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        <b>{hasilPresentase} %</b>
                      </Card.Title>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <p className="card-category">
                        <h3>
                          <b
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 27,
                            }}
                          >
                            Target
                          </b>
                        </h3>
                      </p>
                      <Card.Title
                        as="h2"
                        style={{
                          color: "#26DB53",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        <b>100%</b>
                      </Card.Title>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* </Card.Body> */}
            </Card>
          </Col>

          <Col lg="6" sm="6">
            <Row className="mb-3">
              <Col md="6">
                <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                  Tanggal Awal
                </h4>
                <Form.Group
                  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                    onChange={(e) => {
                      e.toDate().setHours(0, 0, 0, 0),
                        setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                    }}
                    value={dariTgl}
                    initialValue={dariTgl}
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="6">
                <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                  Tanggal Akhir
                </h4>
                <Form.Group
                  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                    onChange={(e) => {
                      e.toDate().setHours(23, 59, 0, 0),
                        setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                    }}
                    value={smpTgl}
                    initialValue={smpTgl}
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="6" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#10A674",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Pencairan</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {`Rp ${totalNominalPencairan.toLocaleString("id-ID")}`}
                    {/* {totalNominalPencairan.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })} */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="6" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#10A674",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Total Target</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {`Rp ${totalNominalTarget.toLocaleString("id-ID")}`}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg="6" sm="6">
            <h4
              className="title mt-2"
              style={{ fontWeight: "bold", marginBottom: 27 }}
            >
              Informasi
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#ED5E63",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Area Manager</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalAM.totalAm} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#ED5E63",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Supervisor</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalSpv.totalSpv} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#ED5E63",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Marketing</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalMarketing.totalSm} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              {auth.role === "admin" || auth.role === "super admin" ? (
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#ED5E63",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>SM Sudah Login</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketing.totalLogin} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
              ) : null}

              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#ED5E63",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Cabang</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalCbg.totalCabang} Cabang
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg="6" sm="6">
            <div className="status-container">
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Status
                {auth.role !== "marketing" ||
                auth.role !== "direct marketing" ? (
                  <Button
                    className="btn-wd mr-1"
                    onClick={() => {
                      setModalExport(true);
                    }}
                    // variant="success"
                    style={{
                      marginLeft: 20,
                      backgroundColor: "#DEFAE5",
                      fontWeight: "bold",
                      color: "#78A083",
                      borderColor: "#4C787E",
                    }}
                  >
                    Export Pengajuan
                    <img
                      alt="..."
                      src={require("assets/img/excel.png").default}
                      style={{
                        width: "22px",
                        height: "25px",
                        marginLeft: 10,
                      }}
                    />
                  </Button>
                ) : null}
              </h4>
            </div>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#FED62B",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "black" }}>menunggu</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {tampilTotalPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={60} />
                      </SkeletonTheme>
                    ) : (
                      tampilTotalPengajuan.menunggu
                    )}
                    {/* {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.menunggu} */}
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#FED62B",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "black" }}>diajukan</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.diajukan}
                  </Card.Body>
                </Card>
              </Col> */}
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#FED62B",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "black" }}>Revisi</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {tampilTotalPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={60} />
                      </SkeletonTheme>
                    ) : (
                      tampilTotalPengajuan.revisi
                    )}
                    {/* {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.revisi} */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#FED62B",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "black" }}>Diproses</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {tampilTotalPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={60} />
                      </SkeletonTheme>
                    ) : (
                      tampilTotalPengajuan.proses
                    )}
                    {/* {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.proses} */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#FED62B",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "black" }}>Disetujui</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {tampilTotalPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={60} />
                      </SkeletonTheme>
                    ) : (
                      tampilTotalPengajuan.disetujui
                    )}
                    {/* {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.disetujui} */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#FED62B",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "black" }}>Ditolak</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {tampilTotalPengajuan.length === 0 ? (
                      <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                        <Skeleton height={"100%"} width={60} />
                      </SkeletonTheme>
                    ) : (
                      tampilTotalPengajuan.ditolak
                    )}
                    {/* {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.ditolak} */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                  Cabang
                  <hr />
                </Card.Title>
              </Card.Header>
              <Row>
                <Col md="12">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#ADBC9F",
                          width: 15,
                          height: 15,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Pencairan cabang</label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#436850",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Target Cabang</label>
                    </div>
                    <VictoryChart
                      height={280}
                      width={850}
                      style={{ parent: { marginLeft: "1rem" } }}
                      domainPadding={{ x: 50 }}
                    >
                      <VictoryGroup
                        offset={13}
                        colorScale={["#ADBC9F", "#436850"]}
                      >
                        <VictoryBar
                          data={chartData.series[0].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#ADBC9F",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartData.series[1].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue =
                            //   decimalDigits && decimalDigits.length > 2
                            //     ? -5
                            //     : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#436850",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        label="Nama Cabang"
                        tickLabelComponent={
                          <VictoryLabel style={{ fontSize: "10px" }} />
                        }
                        style={{
                          axisLabel: {
                            padding: 30,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <VictoryAxis
                        dependentAxis
                        tickCount={6}
                        label="Nominal Pencairan dalam Miliar"
                        style={{
                          axisLabel: {
                            padding: 42.5,
                            fontSize: "9px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                          tickLabels: {
                            fontSize: "10px",
                            // padding: 30,
                            // textAnchor: "start",
                          },
                        }}
                        domain={
                          chartData.series.some((data) =>
                            data.slice(0, 10).some((yValue) => yValue !== 0)
                          )
                            ? undefined
                            : { y: [0, 1] }
                        }
                      />
                    </VictoryChart>
                  </div>
                </Col>
                <Col md="12" style={{ marginTop: 30 }}>
                  <Card
                    style={{
                      border: 0,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: 10,
                      marginRight: 30,
                      marginLeft: 30,
                      // backgroundColor: "#FFF59D",
                      color: "black",
                      padding: 20,
                    }}
                  >
                    <div style={{ marginBottom: 20 }}>
                      <Row>
                        <Col>
                          <h4
                            className="title mt-2"
                            style={{
                              fontWeight: "bold",
                              marginLeft: 20,
                              textAlign: "start",
                            }}
                          >
                            Keterangan Nama Cabang
                            <hr></hr>
                          </h4>
                        </Col>
                        <Col style={{ textAlign: "end" }}>
                          <Button
                            className="mr-1"
                            // variant="primary"
                            style={{
                              marginBottom: 10,
                              fontSize: 16,
                              backgroundColor: "#393E46",
                              border: 0,
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontWeight: "bold",
                            }}
                            onClick={() => setModalDetail(!modalDetail)}
                          >
                            Detail Semua Cabang
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        flexWrap: "wrap",
                        display: "flex",
                      }}
                    >
                      {dataGrafikCabang.map((cabang, index) => {
                        return (
                          <div
                            style={{
                              borderWidth: 2,
                              width: 300,
                              marginTop: 10,
                            }}
                          >
                            <Row key={index}>
                              <Col sm="1">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  {String.fromCharCode(65 + index)}
                                </label>
                              </Col>
                              <Col sm="o">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",

                                    color: "black",
                                  }}
                                >
                                  :
                                </label>
                              </Col>
                              <Col sm="9">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",

                                    color: "black",
                                  }}
                                >
                                  {cabang.nama}
                                </label>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                  <span> {auth.nama} </span>
                  <hr />
                </Card.Title>
                <Col md={"3"}>
                  <label style={{ color: "black", fontSize: 14 }}>
                    Pilih Tahun
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    defaultValue={{ label: yearNow, value: yearNow }}
                    onChange={(value) => {
                      setTahun(value.value);
                    }}
                    options={listYear}
                    // placeholder="Pilih Tahun"
                  />
                  {/* <Form.Control
                    onChange={(e) => {
                      setTahun(e.target.value);
                    }}
                    placeholder="Input Tahun"
                    type="month"
                  ></Form.Control> */}
                  {/* <p style={{ color: "gray", marginTop: 10 }}>
                    Tahun dan bulan saat ini :{" "}
                    {isDateValid
                      ? moment(tahun).format("MMMM YYYY")
                      : "Tidak ada data"}
                  </p> */}
                </Col>
              </Card.Header>
              <Row>
                <Col md="12">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#C4B6B6",
                          width: 15,
                          height: 15,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Pencairan User</label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#7E7474",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Target User</label>
                    </div>
                    <VictoryChart
                      height={250}
                      width={850}
                      style={{ parent: { marginLeft: "1rem" } }}
                      domainPadding={{ x: 50 }}
                    >
                      <VictoryGroup
                        offset={12.5}
                        colorScale={["#C4B6B6", "#7E7474"]}
                      >
                        <VictoryBar
                          data={chartDataUser.series[0].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#C4B6B6",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartDataUser.series[1].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              dy={-2}
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#7E7474",
                                padding: 5,
                              }}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        tickLabelComponent={
                          <VictoryLabel style={{ fontSize: "10px" }} />
                        }
                        label="Bulan"
                        style={{
                          axisLabel: {
                            padding: 30,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <VictoryAxis
                        dependentAxis
                        label="Nominal Pencairan dalam Miliar"
                        style={{
                          axisLabel: {
                            padding: 42.5,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                          tickLabels: {
                            fontSize: "10px",
                          },
                        }}
                        domain={
                          chartDataUser.series.some((data) =>
                            data.some((yValue) => yValue !== 0)
                          )
                            ? undefined
                            : { y: [0, 1] }
                        }
                      />
                    </VictoryChart>
                  </div>
                </Col>
              </Row>

              {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DashboardUtama;
