import React from "react";
import ListUser from "./ListUser";
import ListKorwil from "./ListKorwil";
import ListAreaManager from "./ListAreaManager";
import ListSupervisor from "./ListSupervisor";
import ListMarketing from "./ListMarketing";
import ListBPD from "./ListBPD";
import { Card, Nav, Container, Row, Col, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";

function IndexUser() {
  const auth = useSelector((state) => state.authReducer);
  const [status, setStatus] = React.useState("");

  // React.useEffect(() => {
  //   setStatus(auth.role);
  // }, [auth.role]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  List User
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Tab.Container
                  id="plain-tabs-example"
                  defaultActiveKey={
                    auth.role === "super admin" ? "user" : "korwil"
                  }
                >
                  <Nav role="tablist" variant="tabs">
                    {auth.role === "super admin" ? (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="user"
                          style={{ color: "#6CC417", fontWeight: "bold" }}
                        >
                          User
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="korwil"
                        style={{ color: "#6CC417", fontWeight: "bold" }}
                      >
                        Koordinator Wilayah
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="areamaneger"
                        style={{ color: "#6CC417", fontWeight: "bold" }}
                      >
                        Area Manager
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="supervisor"
                        style={{ color: "#6CC417", fontWeight: "bold" }}
                      >
                        Supervisor
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="marketing"
                        style={{ color: "#6CC417", fontWeight: "bold" }}
                      >
                        Sales Marketing
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="korcab"
                        style={{ color: "#6CC417", fontWeight: "bold" }}
                      >
                        BPD
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="user">
                      <ListUser />
                    </Tab.Pane>

                    <Tab.Pane eventKey="korwil">
                      <ListKorwil />
                    </Tab.Pane>

                    <Tab.Pane eventKey="areamaneger">
                      <ListAreaManager />
                    </Tab.Pane>

                    <Tab.Pane eventKey="supervisor">
                      <ListSupervisor />
                    </Tab.Pane>

                    <Tab.Pane eventKey="marketing">
                      <ListMarketing />
                    </Tab.Pane>
                    <Tab.Pane eventKey="korcab">
                      <ListBPD />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default IndexUser;
